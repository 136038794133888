<template>
 <div>
    
 </div>
</template>

<script>

export default {
 
  data() {
    return {
  
    }
  },
  methods: {
   
  },
  created() {
   
  }
}
</script>
